import * as React from 'react';
import InfoData from '../../info-data';
import './footer.scss';

const Footer = () => {
	return (
		<div className="footer">
			<div className="main-wrapper">
				<div className="footer__col-prime">
					<h3>Simona Šakočienė</h3>
					<p style={{ textAlign: 'center' }}>Individuali psichodinaminė psichoterapija</p>
				</div>
				<div className="footer__col-sec">
					<h3>Kabineto adresas</h3>
					<p>{InfoData.address[0]}</p>
					<p>{InfoData.address[1]}</p>
				</div>
				<div className="footer__col-sec">
					<h3>Kontaktai</h3>
					<a href={`mailto:${InfoData.email}`}>{InfoData.email}</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
